@import 'normalize.css/normalize';
@import 'skeleton.css/skeleton';
@import 'animate.css/animate';

@import '~styles/theme';

* {
  color: $dark-font;
  font-family: $primary-font-family;
}

form.alternative {
  font-weight: 100;
  padding-top: 4rem;
  text-align: center;

  input {
    border: none;
    border-radius: 0;
    display: block;

    &[type='submit'] {
      background-color: $light;
      color: $dark;
      margin: 0 auto;

      &:hover,
      &:focus {
        background-color: $medium;
        color: $dark;
      }
    }

    &:not([type='submit']) {
      margin: 0 auto 3rem auto;
      width: 35rem;
    }
  }
}
