// Site Colors
$lightest: #FFFFFF;
$light: #FFEAF8;
$medium: #FFCBE4;
$dark: #35013F;

// Font Colors
$light-font: #FFEAF8;
$medium-font: #E32985;
$dark-font: #35013F;

// Fonts
$primary-font-family: 'Lato', sans-serif;
$secondary-font-family: 'Dancing Script', cursive;