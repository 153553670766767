@import '~styles/theme';

.Footer {
  background-color: $light;
  padding: 4rem 0;
}

.Footer-ItemTitle {
  margin-bottom: 0.8rem;
  font-weight: 400;
}

.Footer-Item {
  color: $medium-font;
  margin: 0;
  text-align: center;
}

.Footer-Icon {
  stroke: $medium-font;
  padding: 0 0.3rem;
  transition: stroke 0.4s;

  &:hover {
    stroke: $dark-font;
  }
}

.Footer-Column {
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

.Footer-Info {
  padding-top: 2.4rem;

  &:first-of-type {
    text-align: right;
  }
}

.Footer-Spacer {
  margin: 0 0.6rem;
}

@media (max-width: 550px) {
  .Footer-Column,
  .Footer-Column:first-of-type {
    text-align: center;
  }
}
